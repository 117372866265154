const MeetDrCollinsContent = {
  COLLINS_INTRO: `The staff at Collins Orthodontics is dedicated to providing the
    highest quality of orthodontic care in the safest and most
    effective manner possible. Our staff members are compassionate,
    friendly and accommodating. They stay current with new technology
    and techniques by attending continuing education courses on a
    consistent basis. Each member of Dr. Collins’ staff is
    professional, highly skilled and available to answer any concerns
    or questions you may have regarding your orthodontic treatment.`,
  COLLINS_INTRO_ADD: `In addition to being selected as a member of Phi Beta Kappa, Dr.
Collins graduated Summa Cum Laude in the top 3% of his class at
both St. Olaf College and the University of Minnesota School of
Dentistry. During dental school, he received numerous awards for
scholarship and clinical ability, including the prestigious
Betzner Scholarship, an award given for having “technical skill
above and beyond his peers.”`,
  COLLINS_ORTHO_TRAINING: `In 2010, Dr. Collins was honored to be the only dentist accepted
to Mayo Clinic for his orthodontic training amongst hundreds of
top dental school applicants nationwide. He completed the
three-year orthodontic residency at Mayo Clinic, where he had the
opportunity to further develop his passion for orthodontics. At
the American Association of Orthodontists Annual Meeting, Dr.
Collins received the 1st place AAO Charley Schultz Award among
research participants from all over the world for his cutting edge
research on the use of new imaging techniques in orthodontics that
are safer for children and patients.`,
  COLLINS_ORTHO_TRAINING_ADD: `He has also taken the extra step to become a board-certified
orthodontist as a Diplomate of the American Board of Orthodontics.
Only about 25% of practicing orthodontists nationwide have
achieved the status of becoming “board-certified” and Dr. Collins
did so within one year of completing his orthodontic training at
Mayo Clinic.`,
  COLLINS_COMM_AND_FAMILY: `Dr. Collins has many interests outside of creating beautiful
smiles, including playing the guitar, soccer coaching, traveling,
and spending time with his wife, Kimberly, and children Caden,
Grayson and Lennon. In addition to coaching and spending time with
his family, Dr. Collins also enjoys attending all types of
community activities of his patients including theater, dance,
music, and sporting events. It is important to him not only to
provide his patients with beautiful and healthy smiles, but also
to support them within the community.`,
}

export default MeetDrCollinsContent
